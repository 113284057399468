import React, { useState } from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../../components/Layout"
import { ResourcePost } from "../../components/ResourcePost"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import ToggleButton from "react-bootstrap/ToggleButton"

import "./styles.css"

export default function Resources() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [clickedButton, setClickedButton] = useState("all")

  const resourcesList = [

    {
      coverSrc: "/img/slack-bot.png",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "https://www.prweb.com/releases/irisagent-launches-revolutionary-ai-bot-for-slack-transforming-workspace-communication-302365062.html",
      type: "articles",
      createdAt: new Date("2025-02-03"),
      title:
        "IrisAgent Launches Revolutionary AI Bot for Slack",
      content:
        "IrisAgent today announced the launch of AI Bot for Slack, a game-changing integration that promises to revolutionize workplace communication and productivity.",
    },{
      coverSrc: "/img/brands/zuora.png",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "/zuora/",
      type: "case studies",
      createdAt: new Date("2024-09-23"),
      title:
        "How Zuora responds 10x faster with IrisAgent",
      content:
        "Zuora transforms its support experience with automated triaging, intelligent routing, and self-service resolutions using IrisAgent AI Support Automation",
    },
    {
      coverSrc: "/img/resources/gen-ai-webinar-post.png",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "/gen-ai-webinar/",
      type: "webinars",
      createdAt: new Date("2024-09-17"),
      title:
        "AI-Ready Support: Equipping Your Team for the GenAI Era",
      content:
        "Listen to support leaders from Salesforce and IrisAgent on how to revolutionize your customer support by harnessing the power of Generative AI (GenAI)",
    },
    {
      coverSrc: "/img/ai_checklist.png",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "/ai-checklist/",
      lengthInMinutes: 8,
      type: "collaterals",
      createdAt: new Date("2024-05-17"),
      title: "AI Readiness Checklist",
      content:
        "Is your customer support team Generative AI-ready? Download our AI Readiness checklist for free.",
    },

    {
      coverSrc: "/img/resources/qdrant.jpeg",
      logoSrc: "/img/logos/qdrant_logo.jpeg",
      src: "https://qdrant.tech/blog/iris-agent-qdrant/",
      type: "articles",
      createdAt: new Date("2024-03-06"),
      title:
        "IrisAgent and Qdrant: Redefining Customer Support with AI",
      content:
        "Learn how IrisAgent leverages GenAI, fine-tuned LLMs, RAG in partnership with Qdrant",
    },{
      coverSrc: "/img/teachmint-case-study.svg",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "/teachmint-case-study/",
      type: "case studies",
      createdAt: new Date("2023-06-23"),
      title:
        "How Teachmint automated tagging and improved MTTR",
      content:
        "This case study discusses the implementation and impact of IrisAgent in Teachmint's environment.",
    },
    {
      coverSrc: "/img/irisgpt.svg",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "/gpt-whitepaper-download/",
      type: "whitepapers",
      createdAt: new Date("2023-04-27"),
      title:
        "Getting Started with Generative AI (GPT) for use cases in Customer Support",
      content:
        "This white paper explores the use of GPT-based AI models for customer support",
    },
    {
      coverSrc: "/img/resources/post 16.svg",
      logoSrc: "/img/logos/csm-logo@48x.svg",
      src:
        "https://www.customerservicemanager.com/how-cx-insights-from-automated-tagging-of-support-tickets-help-product-managers/",
      type: "articles",
      createdAt: new Date("2023-01-26"),
      title:
        "How CX Insights From Automated Tagging of Support Tickets Help Product Managers",
      content:
        "In this article, Palak Dalal Bhatia, CEO and Founder of IrisAgent, explains how AI-powered automated tagging can provide... ",
    }
    ,
    {
      coverSrc: "/img/resources/post 15.svg",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "/sd-expo-panel/",
      type: "webinars",
      createdAt: new Date("2022-12-07"),
      title:
        "SupportDriven Expo Panel: Getting Started with AI for Support Operations",
      content:
        "Listen to support leaders from Agorapulse and Vanessa Marshall use the simple yet powerful capability of AI-powered tagging... ",
    },
    {
      coverSrc: "/img/resources/post 14.svg",
      logoSrc: "/img/logos/cision.svg",
      src: "https://www.prweb.com/releases/2022/10/prweb18984776.htm",
      type: "articles",
      createdAt: new Date("2022-11-06"),
      title:
        "IrisAgent, a leader in support automation, is now SOC 2 certified",
      content:
        "IrisAgent Inc., a leader in AI-Powered Support Operations, is now SOC 2 certified with enterprise-grade security controls. IrisAgent delivers real-time contextual product... ",
    },
    {
      coverSrc: "/img/resources/post 13.jpeg",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "/csc-webinar/",
      type: "webinars",
      createdAt: new Date("2022-10-28"),
      title:
        "CSC Webinar: Increase renewals and prevent at-risk accounts with proactive AI-powered support automation",
      content:
        "Accessing real-time insights into product issues, potential customer churn, and...",
    },

    {
      coverSrc: "/img/resources/post 3.svg",
      logoSrc: "/img/logos/cision.svg",
      src: "https://www.prweb.com/releases/2022/9/prweb18905236.htm",
      type: "articles",
      createdAt: new Date("2022-09-20"),
      title:
        "IrisAgent AI-powered Support Operations Automation now available on Salesforce AppExchange",
      content:
        "With IrisAgent for Salesforce Integration installed, support agents now get... ",
    },

    {
      coverSrc: "/img/resources/post 1.svg",
      logoSrc: "/img/logos/forbes.svg",
      src:
        "https://www.forbes.com/sites/forbestechcouncil/2022/08/24/how-does-product-led-growth-impact-customer-support/",
      type: "articles",
      createdAt: new Date("2022-09-08"),
      author: "Forbes",
      title: "How Does Product-Led Growth Impact Customer Support?",
      content:
        "Understanding how customers use your product—even those not yet paying to use it—can reveal many product insights.",
    },

    {
      coverSrc: "/img/resources/post 2.svg",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "/plg-whitepaper-download/",
      type: "whitepapers",
      createdAt: new Date("2022-08-12"),
      title:
        "The Business Case for AI powered Support Automation for Product-Led Growth",
      content:
        "PLG strategy to put the product at the forefront of the customer journey",
    },

    {
      coverSrc: "/img/resources/post 3.svg",
      logoSrc: "/img/logos/cision.svg",
      src:
        "https://www.prweb.com/releases/irisagent_launches_on_zendesk_and_intercom_marketplaces/prweb18788482.htm",
      type: "articles",
      createdAt: new Date("2022-07-18"),
      title: "IrisAgent launches on Zendesk and Intercom marketplaces",
      content:
        "Bringing AI-powered proactive, contextual customer support to your favorite ticketing platforms.",
    },

    {
      coverSrc: "/img/resources/post 4.svg",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "/webinar/",
      type: "webinars",
      createdAt: new Date("2022-07-14"),
      title:
        "How to Improve Workflows between Product, Engineering and Support on Aug 3",
      content:
        "Learn how to best integrate your CRM, support platforms, and DevOps tools to...",
    },

    {
      coverSrc: "/img/resources/post 5.svg",
      logoSrc: "/img/logos/CustomerSmartService.svg",
      src:
        "http://www.smartcustomerservice.com/Columns/Vendor-Views/Essential-Elements-for-Breaking-Information-Silos-to-Improve-Customer-Support-153918.aspx",
      type: "articles",
      createdAt: new Date("2022-07-14"),
      author: "Smart Customer Service",
      title:
        "Essential Elements for Breaking Information Silos to Improve Customer Support",
      content:
        "With a proactive approach, silo-free teams can work better together to ensure each...",
    },


    {
      coverSrc: "/img/resources/post 6.svg",
      logoSrc: "/img/logos/iris-agent-logo@48x.svg",
      src: "/product-summary/",
      lengthInMinutes: 8,
      type: "collaterals",
      createdAt: new Date("2022-05-15"),
      title: "IrisAgent Product Summary",
      content:
        "Learn more about IrisAgent's AI-powered solution for customer support teams..",
    },

    {
      coverSrc: "/img/resources/post 7.svg",
      logoSrc: "/img/logos/cxc-logo@48x.svg",
      src:
        "https://cxchronicles.com/173-cxchronicles-podcast-173-with-palak-dalal-bhatia/",
      lengthInMinutes: 37,
      type: "podcasts",
      createdAt: new Date("2022-06-08"),
      author: "The CXChronicles Podcast",
      title: "CXChronicles Podcast with IrisAgent's CEO",
      content:
        "In this episode of CXCP, Palak and Adrian chat through how she has tackled The Four CX Pillars: Team,  Tools, Process & Feedback...",
    },

    {
      coverSrc: "/img/resources/post 8.svg",
      logoSrc: "/img/logos/csm-logo@48x.svg",
      src:
        "https://www.customerservicemanager.com/monitor-customer-health-from-siloed-information-to-prevent-churn/",
      lengthInMinutes: 8,
      type: "articles",
      createdAt: new Date("2022-10-01"),
      author: "The Magazine for Customer Service Managers & Professionals",
      title: "Monitor Customer Health from Siloed Information to Prevent Churn",
      content:
        "Understanding customer health is critical to the long-term success of any comp...",
    },

    {
      coverSrc: "/img/resources/post 9.svg",
      logoSrc: "/img/logos/csm-logo@48x.svg",
      src:
        "https://www.customerservicemanager.com/moving-toward-predictive-customer-analytics-for-excellent-customer-support/",
      lengthInMinutes: 6,
      createdAt: new Date("2022-10-01"),
      type: "articles",
      author: "The Magazine for Customer Service Managers & Professionals",
      title:
        "Moving Toward Predictive Customer Analytics for Excellent Customer Support",
      content:
        "Historically, customer support teams have taken a reactive approach to custom...",
    },

    {
      coverSrc: "/img/resources/post 10.svg",
      logoSrc: "/img/logos/cio-review-2021-logo@96x.svg",
      src: "https://irisagent.com/files/IrisAgent-CIOReview-Award.pdf",
      logoType: "large",
      lengthInMinutes: 6,
      type: "awards",
      author: "CIO Review",
      subText: "CIO Review",
      createdAt: new Date("2021-09-01"),
      title:
        "IrisAgent: Most Promising Digital Experience Solution Providers 2021 by CIO Review",
      content: "Offering a Proactive Customer Support Platform",
    },

    {
      coverSrc: "/img/resources/post 11.svg",
      logoSrc: "/img/logos/silicon-review-logo@96x.svg",
      src:
        "https://irisagent.com/files/IrisAgent-20-Amazing-Startups-of-the-Year-2022.pdf",
      logoType: "large",
      lengthInMinutes: 6,
      type: "awards",
      author: "The Silicon Review",
      subText: "The Silicon Review",
      createdAt: new Date("2021-01-02"),
      title:
        "IrisAgent: 20 Amazing Startups of the Year 2022 by The Silicon Review",
      content: "IrisAgent Makes Customer Support Better for Everyone",
    },
  ]

  const ButtonGroup = () => {
    const tabItemsArray = [
      "all",
      "case studies",
      "webinars",
      "whitepapers",
      "articles",
      "collaterals",
      "awards",
      "podcasts"
    ]
    const handleClick = (buttonLabel: any) => {
      setClickedButton(buttonLabel)
      // onChangeType(buttonLabels[id]);
    }

    return (
      <div>
        {tabItemsArray.map((buttonLabel: any, i: any) => (
          <button
            key={i}
            name={i}
            onClick={() => handleClick(buttonLabel)}
            className="tabs-button"
            style={
              clickedButton === buttonLabel
                ? { background: "var(--primary-color)" }
                : {
                    background:
                      "rgba(var(--primary-color), 0.6)"
                  }
            }
          >
            <p
              style={
                clickedButton === buttonLabel
                  ? { color: "white" }
                  : { color: "var(--dark-background-color)" }
              }
              className="tabs-button-text"
            >
              {buttonLabel}
            </p>
          </button>
        ))}
      </div>
    )
  }
  return (
    <Layout
      title="Explore The Resources From IrisAgent: Learn More About Customer Support"
      keywords="resources, proactive AI customer support, customer support automation, Improve CSAT, Improve MTTR"
      description="Explore the resources from IrisAgent inlcuding webinars, whitepapers, articles, case studies, collaterals and podcasts. Learn how AI is transforming customer support."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/resources/"
        />
      </Helmet>
      <section className="app-resources-page-section">
        <h1>Resources</h1>
        <div className="tabs">
          <ButtonGroup />
        </div>
        <div className="resources-list">
          {resourcesList
            .filter(resource => {
              if (clickedButton !== "all") {
                return resource.type === clickedButton ? resource : null
              }
              return resource
            })
            .map(resource => {
              return (
                <ResourcePost
                  coverSrc={resource.coverSrc}
                  logoSrc={resource.logoSrc}
                  src={resource.src}
                  type={resource.type}
                  createdAt={resource.createdAt}
                  title={resource.title}
                  content={resource.content}
                  lengthInMinutes={resource.lengthInMinutes}
                  author={resource.author}
                  subText={resource.subText}
                />
              )
            })}

          {/* <button>MORE RESOURCES <img src="/img/arrow-right-purple.svg" alt="arrow right icon" /></button> */}
        </div>
      </section>
      <div className="subscribe-ad-content">
        <form
          noValidate={false}
          accept-charset="UTF-8"
          action="https://forms.hsforms.com/submissions/v3/public/submit/formsnext/multipart/7494256/6a1d4af4-75c3-4ada-83be-49b69f32a4f4"
          encType="multipart/form-data"
          method="POST"
          data-form-id="6a1d4af4-75c3-4ada-83be-49b69f32a4f4"
          data-portal-id="7494256"
          onSubmit={e => {
            e.preventDefault()
            setIsFormSubmitted(true)
          }}
        >
          <div style={{ width: "100%" }}>
            <h2 className="subscribe-title">Subscribe to our blog</h2>

            {isFormSubmitted ? (
              <span>Thanks for submitting the form.</span>
            ) : (
              <>
                <div className="subscribe-placeholder">
                  <input
                    className="subscribe-input"
                    required
                    type="email"
                    placeholder="Enter your email address"
                  />
                </div>

                <button type="submit" className="subscribe-now">
                  STAY UPDATED
                </button>
              </>
            )}
          </div>
          <img
            src="/img/resources-subscribe-to-our-blog.svg"
            alt="subscribe to our blog"
          />
        </form>
      </div>
    </Layout>
  )
}
